const priceTable = {
  CC: {
    US: {
      price: {
        range: {
          min: 408,
          max: 448,
        },
        regular: 349,
        offer: {
          singleUnit: 314,
          mutipleUnits: 279,
        },
      },
    },
    CA: {
      price: {
        range: {
          min: 550,
          max: 599,
        },
        regular: 470,
        offer: {
          singleUnit: 423,
          mutipleUnits: 376,
        },
      },
    },
    GB: {
      price: {
        range: {
          min: 359,
          max: 389,
        },
        regular: 309,
        offer: {
          singleUnit: 278,
          mutipleUnits: 247,
        },
      },
    },
    AU: {
      price: {
        range: {
          min: 628,
          max: 683,
        },
        regular: 548,
        offer: {
          singleUnit: 493,
          mutipleUnits: 438,
        },
      },
    },
    EU: {
      price: {
        range: {
          min: 408,
          max: 448,
        },
        regular: 349,
        offer: {
          singleUnit: 314,
          mutipleUnits: 279,
        },
      },
    },
  },

  BB: {
    US: {
      price: {
        range: {
          single: {
            min: 408,
            max: 448,
          },
          multi: {
            min: 458,
            max: 498,
          },
          regular: {
            min: 408,
            max: 498,
          },
        },
        regular: 349,
        offer: {
          singleUnit: 279,
          mutipleUnits: 279,
        },
      },
    },
    CA: {
      price: {
        range: {
          single: {
            min: 550,
            max: 599,
          },
          multi: {
            min: 610,
            max: 659,
          },
          regular: {
            min: 550,
            max: 659,
          },
        },
        regular: 470,
        offer: {
          singleUnit: 376,
          mutipleUnits: 377,
        },
      },
    },
    GB: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
    AU: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
    EU: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
  },

  HW: {
    US: {
      price: {
        range: {
          single: {
            min: 428,
            max: null,
          },
          multi: {
            min: 478,
            max: null,
          },
          regular: {
            min: 428,
            max: 478,
          },
        },
        regular: 349,
        offer: {
          singleUnit: 262,
          mutipleUnits: 262,
        },
      },
    },
    CA: {
      price: {
        range: {
          single: {
            min: 580,
            max: null,
          },
          multi: {
            min: 640,
            max: null,
          },
          regular: {
            min: 580,
            max: 640,
          },
        },
        regular: 470,
        offer: {
          singleUnit: 352,
          mutipleUnits: 352,
        },
      },
    },
    GB: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
    AU: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
    EU: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
  },

  RP: {
    US: {
      price: {
        range: {
          single: {
            min: 418,
            max: null,
          },
          multi: {
            min: 468,
            max: null,
          },
          regular: {
            min: 418,
            max: 468,
          },
        },
        regular: 349,
        offer: {
          singleUnit: 262,
          mutipleUnits: 262,
        },
      },
    },
    CA: {
      price: {
        range: {
          single: {
            min: 560,
            max: null,
          },
          multi: {
            min: 620,
            max: null,
          },
          regular: {
            min: 560,
            max: 620,
          },
        },
        regular: 470,
        offer: {
          singleUnit: 352,
          mutipleUnits: 352,
        },
      },
    },
    GB: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
    AU: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
    EU: {
      price: {
        range: {
          single: {
            min: null,
            max: null,
          },
          multi: {
            min: null,
            max: null,
          },
          regular: {
            min: null,
            max: null,
          },
        },
        regular: null,
        offer: {
          singleUnit: null,
          mutipleUnits: null,
        },
      },
    },
  },
};

export default priceTable;
