import React from 'react';
import { Helmet } from 'react-helmet-async';

const AccessibilityPolicy = ({ staticContext = {}}) => {
  staticContext.notFound = true;

  return (
    <>
      <Helmet>
        <title>Accessibility Policy</title>
      </Helmet>
      <section className='general-content general-content--alt general-content--left'>
        <div className='container'>
          <div className='general-content__content' style={{ maxWidth: `${780}px` }}>
            <h1 className='general-content__title'>
              Accessibility Policy
            </h1>
            <div className='general-content__description'>
              <p>   Mila is committed to making our website accessible for all customers, including those with disabilities.</p>
              <p>Consistent with this goal, we have taken several steps to enhance our site and increase its usability by those who access the web using assistive technology. Our efforts are guided by the Web Content Accessibility Guidelines, version 2.1, Level A and AA (WCAG 2.1 AA). We will continue to enhance our digital properties to deliver accessible experiences.</p>
              <p>
                If you experience difficulty accessing our website content, reach out to our Care Squad at
                {' '}
                <a href='mailto:vip@milacares.com'>vip@milacares.com</a>
                {' '}
                for assistance and to provide feedback.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

AccessibilityPolicy.defaultProps = {
  staticContext: {},
};

export default {
  component: AccessibilityPolicy,
};
