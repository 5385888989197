import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import {
  fetchReviewsPreview,
  selectReviewsPreviewStatus,
} from '../features/reviews/reviewsSlice';
import {
  fetchRegionalPrices,
  selectRegionalPricesStatus,
} from '../features/regionalPrices/regionalPricesSlice';
import { selectLocalizationData } from '../features/localization/localizationSlice';
import { STATUS } from '../../shared/enums';
import FallbackPlaceholder from '../components/common/FallbackPlaceholder';

const HeroJuly2024 = loadable(() => import(/* webpackPrefetch: true */ '../components/HeroJuly2024'));
const AboutBlock = loadable(() => import('../components/landing/AboutBlock'));
const AppBlock = loadable(() => import('../components/landing/AppBlock'));
const ContentBlock = loadable(() => import('../components/landing/ContentBlock'));
const FeatureBlock = loadable(() => import('../components/landing/FeatureBlock'));
const FeatureBlockTopGap = loadable(() => import('../components/landing/FeatureBlockTopGap'));
const HighlightedBlock = loadable(() => import('../components/landing/HighlightedBlock'));
const OptionBlock = loadable(() => import('../components/landing/OptionBlock'));
const OrderBlock = loadable(() => import('../components/common/OrderBlock'));
const ProductBlock = loadable(() => import('../components/landing/ProductBlock'));
const ReviewColumn = loadable(() => import('../components/landing/ReviewColumn'));
const ReviewSlider = loadable(() => import('../components/landing/ReviewSlider'));
const ThumbnailBlock = loadable(() => import('../components/landing/ThumbnailBlock'));
const VideoBlock = loadable(() => import('../components/landing/VideoBlock'));
const VoiceBlock = loadable(() => import('../components/landing/VoiceBlock'));

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const Landing = props => {
  const { route } = props;
  const dispatch = useDispatch();
  const localization = useSelector(selectLocalizationData);
  const regionalPricesStatus = useSelector(selectRegionalPricesStatus);
  const reviewsStatus = useSelector(selectReviewsPreviewStatus);
  const { PREFETCHED, SUCCEEDED } = STATUS;

  useEffect(() => {
    if (![ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus)) {
      dispatch(fetchRegionalPrices(localization));
    }
    if (![ PREFETCHED, SUCCEEDED ].includes(reviewsStatus)) {
      dispatch(fetchReviewsPreview());
    }
    return undefined;
  }, []);

  return (
    <>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <script src={YOTPO_SRC} async />
      </Helmet>
      <HeroJuly2024
        localization={localization}
        fallback={<FallbackPlaceholder />}
      />
      <ThumbnailBlock />
      <ReviewSlider />
      <VideoBlock />
      <FeatureBlock />
      <FeatureBlockTopGap />
      <HighlightedBlock />
      {[ PREFETCHED, SUCCEEDED ].includes(reviewsStatus) && <ReviewColumn />}
      {[ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus) && (
        <ProductBlock />
      )}
      <AppBlock />
      <OptionBlock />
      <VoiceBlock />
      {[ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus) && <AboutBlock />}
      <ContentBlock />
      {[ PREFETCHED, SUCCEEDED ].includes(regionalPricesStatus) && <OrderBlock />}
    </>
  );
};

const loadData = store => {
  const {
    localization: { data },
  } = store.getState();
  const actions = [ fetchRegionalPrices(data), fetchReviewsPreview() ];
  return Promise.all(actions.map(action => store.dispatch(action)));
};

export default {
  component: Landing,
  loadData,
};
